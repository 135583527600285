// Update the count down every 1 second
var x = setInterval(function() {

  // Opkomende kerst jaartal opzoeken
  var today = new Date();
  if (today.getDate() > 26 && today.getMonth() === 11) {
  var yyyy = today.getFullYear() +1;
  } else {
    var yyyy = today.getFullYear();
  }

  // Set the date we're counting down to
  var countDownDate = new Date(yyyy + "-12-25").getTime();

  // Get todays date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now + (today.getTimezoneOffset() * 60 * 1000);

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  var daysText = "dagen";
  var hoursText = "uur";
  var minutesText = "minuten";
  var secondsText = "seconden";

  // If the count down is finished, write some text
  // getMonth 0 = January getMonth 11 = December

  // First months
  var currentInfoText = "Nee, het is nog lang geen kerst.";
  document.getElementById("js-current-info-text").innerHTML = currentInfoText;

  // Aug and Sep
  if (today.getMonth() === 7 || today.getMonth() === 8) {
    currentInfoText = "Nee.. het is nog geen kerst.";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Oct
  if (today.getMonth() === 9) {
    currentInfoText = "Ik begrijp je enthousiasme, maar je moet nog heel even wachten.";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Nov
  if (today.getMonth() === 10) {
    currentInfoText = "Nou, het duurt niet lang meer.<br />  Het is nu wel geoorloofd om naar kerstmuziek te luisteren!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Dec
  if (today.getDate() < 25 && today.getMonth() === 11) {
    currentInfoText = "Het is bijna zo ver!<br />  Zet die kerstmuziek maar aan!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Christmas
  if (today.getMonth() === 11 && (today.getDate() === 25 || today.getDate() === 26)) {
    document.getElementById('js-countdown-kerst').style.display = 'none';
    currentInfoText = "JA, het is kerst! <br /> Fijne kerst!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Dec after Christmas
  if (today.getDate() > 26 && today.getMonth() === 11) {
    document.getElementById('js-countdown-kerst').style.display = 'block';
    currentInfoText = "Nee, nog lang niet. <br / > Het is net kerst geweest..";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Refresh everyday at 5:00 because of snow stop
  if (today.getHours() == 5 && today.getMinutes() === 00 && today.getSeconds() === 00) {
    window.location.reload(true);
  }

  // Display the result in the elements
  document.getElementById("js-number-days").innerHTML = days;
  document.getElementById("js-number-hours").innerHTML = hours;
  document.getElementById("js-number-minutes").innerHTML = minutes;
  document.getElementById("js-number-seconds").innerHTML = seconds;

  document.getElementById("js-text-days").innerHTML = daysText;
  document.getElementById("js-text-hours").innerHTML = hoursText;
  document.getElementById("js-text-minutes").innerHTML = minutesText;
  document.getElementById("js-text-seconds").innerHTML = secondsText;

}, 1000);
